import React from 'react'
import Banners from '../../Components/Banners/Banners'
import Products from '../../Components/Products/Products'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import Cart from '../../Components/Cart/Cart'
import NavbarMobile from '../../Components/NavbarMobile/NavbarMobile'
import { useMediaQuery } from '@react-hook/media-query';
export default function Demo() {
    const isScreenLarge = useMediaQuery('(min-width: 900px)');
    return (
        <section className='demo'>
            <Banners />
            <Products />
            <Footer />

            <Cart />
            <NavbarMobile />

            {isScreenLarge ?
                <>
                    <BtnWhatsapp />
                </> :
                <>

                </>}
        </section>
    )
}
