import React from 'react'
import NavbarDashboard from '../../Components/Admin/NavbarDashboard/NavbarDashboard'
export default function Header() {
    return (
        <div>
            <NavbarDashboard />

        </div>
    )
}
