import React from 'react'
import './DetailLoading.css'
export default function DetailLoading() {
    return (
        <div className='detailLoading'>
            <div className='imgLoadingDetail'>

            </div>
            <div className='textLoadingDetail'>
                <span>

                </span>
                <span>

                </span>
                <span>

                </span>
                <span>

                </span>
                <span>

                </span>
                <span>

                </span>
                <span>

                </span> <span>

                </span> <span>

                </span> <span>

                </span>
            </div>

        </div>
    )
}
