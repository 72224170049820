import React, { useState, useEffect } from 'react';
import './InfoUser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from 'react-router-dom';
import baseURL from '../../url';

export default function InfoUser() {
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [passwordChangeMessage, setPasswordChangeMessage] = useState('');

    useEffect(() => {
        fetch(`${baseURL}/userLogued.php`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsuario(data);
                setLoading(false);
                console.log(data);
            })
            .catch(error => {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            });
    }, []);

    const handlePasswordChange = () => {
        const payload = { contrasena: newPassword };
        fetch(`${baseURL}/updatePassword.php?idUsuario=${usuario.idUsuario}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setPasswordChangeMessage('Password updated successfully');
                console.log('Password updated:', data);
            })
            .catch(error => {
                setPasswordChangeMessage('Error updating password');
                console.error('Error updating password:', error);
            });
    };

    return (
        <div>
            {loading ? (
                <div>Cargando...</div>
            ) : usuario.idUsuario ? (
                <div>
                    <Anchor to={``} className='btn-sesion'>
                        <FontAwesomeIcon icon={faUser} className='icon' />
                        {usuario.nombre.slice(0, 13)}
                    </Anchor>
                    <div className='password-change-form'>
                        <input
                            type='password'
                            placeholder='New Password'
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                        />
                        <button onClick={handlePasswordChange}>Change Password</button>
                        {passwordChangeMessage && <p>{passwordChangeMessage}</p>}
                    </div>
                </div>
            ) : (
                <Anchor to={``} className='btn-sesion'>
                    <FontAwesomeIcon icon={faUser} className='icon' />
                    Mi Perfil
                </Anchor>
            )}
        </div>
    );
}
